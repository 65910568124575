<template>
  <div class="mall">
    <Head classA="1" headBg="3"></Head>
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-no-swiping">
      <swiper-slide>
        <div class="swiperFirstWrap">
          <div class="swiperFirst">
            <div class="textWrap">
              <p><span style="margin-right: 16px;">药发采</span>一站式药品批发采购平台</p>
            </div>
            <img src="../../assets/images/mall/bannerImg.png" width="551" />
          </div>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <div class="mainWhite">
      <div class="main cloudClinicTop">
        <h3>药发采</h3>
        <div class="topImg">
          <img src="../../assets/images/mall/topImg.png" width="1128" />
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="mainMiddle">
        <div class="wayWrap">
          <div class="wayLeft">
            <img
              class="wayImg"
              src="../../assets/images//mall/wayImg.png"
              width="535"
            />
            <img
              class="wayImgBg Rotation"
              src="../../assets/images//mall/wayImgBg.png"
              width="444"
            />
          </div>
          <div class="wayRight">
            <ul class="wayRightUl">
              <li>
                <span>全网比价</span>
                <img src="../../assets/images/mall/wayIcon1.png" width="56" height="60" />
              </li>
              <li>
                <span>海量药品</span>
                <img src="../../assets/images/mall/drug.png" width="56" height="60" />
              </li>
              <li>
                <span>智能推荐</span>
                <img src="../../assets/images/mall/cloud.png" width="56" height="60" />
              </li>
              <li>
                <span>极速下单</span>
                <img src="../../assets/images/mall/wayIcon2.png" width="56" height="60" />
              </li>
              <li>
                <span>全国配送</span>
                <img src="../../assets/images/mall/car.png" width="56" height="60" />
              </li>
              <li>
                <span>一键入库</span>
                <img src="../../assets/images/mall/warehouse.png" width="60" height="60" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="mainMiddle" style="padding-bottom: 30px;">
        <h3>药发采</h3>
        <h4>颠覆基层药品采购方式</h4>
        <div class="typeWrap">
          <div class="typeLeft">
            <img src="../../assets/images/mall/typeLeft.png" width="454" height="260" />
            <dl>
              <dt>
                <h5>传统</h5>
              </dt>
              <dd>电话下单</dd>
              <dd>单一渠道</dd>
              <dd>单一客户</dd>
            </dl>
          </div>
          <div class="typeRight">
            <img src="../../assets/images/mall/typeRight.png" width="454" height="260" />
            <dl>
              <dt>
                <h5>现在</h5>
              </dt>
              <dd>网络下单</dd>
              <dd>多渠道比价</dd>
              <dd>多渠道获客</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="mainWhite">
      <div class="main">
        <h3>合作厂家</h3>
        <div class="cooperateImg">
          <img src="../../assets/images/mall/cooperateImg2x.jpg" width="986" />
        </div>
      </div>
    </div>
    <Foot bgcolor="1"></Foot>
  </div>
</template>
<style lang="scss" scoped>
.mall {
  .swiperFirstWrap {
    width: 100%;
    height: 550px;
    background: rgba(0, 139, 255, 1);
    .swiperFirst {
      width: 79.17%;
      max-width: 1140px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .textWrap {
        text-align: left;
        h4 {
          font-size: 36px;
          color: #fff;
          font-weight: 600;
          padding-bottom: 12px;
        }
        p {
          font-size: 40px;
          color: #fff;
        }
      }
    }
  }

  .mainWhite {
    width: 100%;
    background: #fff;
    .main {
      width: 79.17%;
      max-width: 1140px;
      margin: 0 auto;
      h3 {
        padding: 60px 0 0 0;
        font-size: 28px;
        color: #1a1c1f;
        font-weight: bold;
      }
      h4 {
        padding: 20px 0 43px 0;
        font-size: 22px;
        color: #1a1c1f;
      }
    }
    .cloudClinicTop {
      .topImg {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%;
        text-align: center;
        img {
          width: 100%;
        }
      }
      .tabWrap {
        display: flex;
        padding-bottom: 60px;
        .tabTitle {
          padding-right: 48px;
          border-right: 1px solid rgb(203, 203, 204);
          margin-right: 48px;
          li {
            width: 152px;
            text-align: center;
            color: #1a1c1f;
            font-size: 16px;
            padding: 13px 0;
            box-sizing: border-box;
            cursor: pointer;
          }
          li.active {
            background: rgba(0, 145, 255, 1);
            box-shadow: 0px 4px 30px 0px rgba(0, 145, 255, 0.5);
            border-radius: 2px;
            color: #fff;
          }
        }
        .tabBox {
          .tabCon {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            img {
              display: block;
            }
          }
        }
      }
    }
    .mallWrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 40px 0;
      .mallLeft {
        .mallUl {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          li {
            position: relative;
            width: 278px;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px 10px 0;
            overflow: hidden;
            border-radius: 10px;
            .mallIBg {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 2;
              width: 100%;
              -ms-transition: 0.6s ease-in;
              -moz-transition: 0.6s ease-in;
              -o-transition: 0.6s ease-in;
              -webkit-transition: 0.6s ease-in;
              transition: 0.6s ease-in;
            }
            .mallCon {
              position: absolute;
              left: 32px;
              text-align: left;
              z-index: 3;
              h6 {
                font-size: 22px;
                font-weight: 600;
                color: rgba(255, 255, 255, 1);
                line-height: 30px;
                letter-spacing: 10px;
              }
              p {
                font-size: 18px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 25px;
                letter-spacing: 4px;
              }
            }
            img {
              z-index: 3;
            }
            .mall-icon {
              position: absolute;
              right: 32px;
            }
          }
          li:hover {
            .mallIBg {
              -ms-transform: scale(1.1);
              -moz-transform: scale(1.1);
              -webkit-transform: scale(1.1);
              -o-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
        }
      }
      .mallRight {
        img {
          max-width: 100%;
        }
      }
    }
    .cooperateImg {
      padding: 50px 0 25px 0;
    }
  }
  .mainMiddle {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;
    h3 {
      padding: 60px 0 0 0;
      font-size: 28px;
      color: #1a1c1f;
      font-weight: bold;
    }
    h4 {
      padding: 20px 0 43px 0;
      font-size: 22px;
      color: #1a1c1f;
    }
    .typeWrap {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;
      dl {
        dt {
          position: relative;
          z-index: 3;
          margin: 35px 0;
        }
        h5 {
          color: #1a1c1f;
          font-size: 24px;
          font-weight: 500;
          position: relative;
          z-index: 5;
        }
        dt::before {
          content: '';
          width: 72px;
          height: 18px;
          position: absolute;
          top: 16px;
          left: 50%;
          margin-left: -36px;
          z-index: 4;
        }
        dd {
          width: 280px;
          height: 52px;
          line-height: 52px;
          border-radius: 26px;
          font-size: 22px;
          margin: 0 auto 20px auto;
        }
      }
      .typeLeft {
        padding-right: 20px;
        dl {
          dt {
          }
          dt::before {
            background: rgba(228, 229, 234, 1);
            box-shadow: 0px 4px 10px 0px rgba(228, 229, 234, 0.72);
          }
          dd {
            background: rgba(228, 229, 234, 1);
            color: #1a1c1f;
          }
        }
      }
      .typeRight {
        dl {
          dt {
          }
          dt::before {
            background: rgba(0, 145, 255, 1);
            box-shadow: 0px 4px 10px 0px rgba(0, 145, 255, 0.5);
          }
          dd {
            background: rgba(0, 145, 255, 1);
            color: #fff;
          }
        }
      }
    }
    .typeWrap::after {
      content: '';
      width: 150px;
      height: 150px;
      background: url('../../assets/images/mall/typeMiddle.png') no-repeat;
      background-size: 100%;
      position: absolute;
      z-index: 2;
      top: 55px;
    }
    .wayWrap {
      display: flex;
      justify-content: space-between;
      padding: 40px 0;
      .wayLeft {
        position: relative;
        .wayImg {
          position: relative;
          z-index: 2;
        }
        .wayImgBg {
          position: absolute;
          z-index: 1;
          top: 86px;
          left: 0;
        }
        @-webkit-keyframes rotation {
          from {
            -webkit-transform: rotate(0deg);
          }
          to {
            -webkit-transform: rotate(360deg);
          }
        }

        .Rotation {
          -webkit-transform: rotate(360deg);
          animation: rotation 8s linear infinite;
          -moz-animation: rotation 8s linear infinite;
          -webkit-animation: rotation 8s linear infinite;
          -o-animation: rotation 8s linear infinite;
        }
      }
      .wayRight {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 65px;
        .wayRightUl {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 240px;
            height: 120px;
            background: rgba(255, 255, 255, 1);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 10px 10px;
            span {
              font-size: 22px;
              font-weight: 500;
              color: rgba(26, 28, 31, 1);
              line-height: 30px;
              letter-spacing: 4px;
            }
            img {
              display: inline-block;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .bg-gray {
    background: #f8f8f8;
  }
  @media screen and (max-width: 1366px) {
    .swiperFirstWrap .swiperFirst {
      width: 90%;
    }
    .mainMiddle .wayWrap .wayLeft {
      width: 47%;
    }
  }
  @media screen and (max-width: 1280px) {
    .mainMiddle {
      .wayWrap {
        .wayLeft {
          .wayImg {
            width: 428px;
          }
          .wayImgBg {
            width: 355px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .swiperFirstWrap {
      height: 320px;
    }
    .swiperFirstWrap {
      .swiperFirst {
        .textWrap {
          h4 {
            font-size: 30px;
          }
          p {
            font-size: 32px;
          }
        }

        img {
          width: 39%;
        }
      }
    }
    .mainWhite {
      .main {
        width: 94%;
      }
      .mallWrap {
        display: block;
        .mallLeft {
          .mallUl {
          }
        }
      }
    }
    .mainWhite .cloudClinicTop .tabWrap .tabBox .tabCon img {
      max-width: 100%;
    }
    .mainMiddle {
      width: 94%;
    }
    .cooperateImg img {
      max-width: 100%;
    }
    .mainMiddle .wayWrap .wayRight .wayRightUl li {
      width: 190px;
    }
  }
  @media screen and (max-width: 768px) {
    .swiperFirstWrap {
      height: 260px;
    }
    .swiperFirstWrap {
      .swiperFirst {
        width: 94%;
        .textWrap {
          h4 {
            font-size: 20px;
          }
          p {
            font-size: 22px;
          }
        }

        img {
          width: 39%;
        }
      }
    }
    .mainMiddle {
      .typeWrap {
        dl {
          dd {
            width: auto !important;
          }
        }
        .typeLeft {
          img {
            width: 100%;
          }
        }
        .typeRight {
          img {
            width: 100%;
          }
        }
      }
      .typeWrap::after {
        width: 80px;
        height: 80px;
      }
      .wayWrap {
        display: block;
        .wayLeft {
          // display: flex;
          // justify-content: center;
          .wayImgBg {
            left: 96px;
          }
        }
        .wayRight {
          padding-left: 0;
          margin: 0 auto;
          .wayRightUl {
            justify-content: space-around;
            width: 100%;
            li {
              width: 22%;
              img {
                width: 40px;
              }
              span {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 612px) {
    .mainWhite .mallWrap .mallLeft .mallUl li {
      width: 86%;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'

export default {
  name: 'home',
  components: {
    Head,
    Foot
  },
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false // 手动切换之后继续自动轮播
        // },
        autoplay: false,
        loop: true
      },
      tabId: 0,
      swiperOption1: {
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1'
        },
        autoplay: false,
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination1',
          clickable: true
        }
      }
    }
  },
  methods: {
    tab (index) {
      this.num = index
    }
  },
  computed: {},
  mounted () {}
}
</script>
